import '/src/Components/PDFViewer.css';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import VueCookies from 'vue-cookies'
import App from './Index.vue'

import router from '@/router/router'
import vuetify from '@/vuetifyPlugin';
import MaskPhone from '@/Directives/MaskPhone.js'
import MaskZip from '@/Directives/MaskZip.js'
import 'material-design-icons/iconfont/material-icons.css';
import '/public/Content/css/Site.css';
import moment from 'moment'
//import '@/registerServiceWorker'
import Vue3Tour from 'vue3-tour'

//import Snackbar from '@/store/snackbarStore';
//import User from '@/store/UserStore';
//import Session from '@/store/sessionStore'

import StackTrace from 'stacktrace-js';

import 'vue3-tour/dist/vue3-tour.css'

const pinia = createPinia();

const app = createApp(App);
//Vue.use(VueTour);
//Vue.use(Vuex);

app.directive('MaskPhone', MaskPhone)
app.directive('MaskZip', MaskZip)

//var store = new Vuex.Store(
//    {
//        modules: {
//            //snackbar: vuexSnackbar,
//            //login: vuexUser,
//            //session: vuexSession
//        },
//        actions:
//        {
//            refresh(context) { }
//        }
//    });
//Vue.config.productionTip = false

//Global Vars

app.config.globalProperties.MyLRCUrl = "https://mylrc.sdlegislature.gov/";
app.config.globalProperties.BasePath = location.protocol + '//' + location.host + '/';
app.config.globalProperties.Environment =  'Production';
app.config.globalProperties.APIURL = location.protocol + '//' + location.host + '/' + 'api/';
app.config.globalProperties.errorURL = location.protocol + '//' + location.host + '/' + 'api/' + 'Errors/';
app.config.globalProperties.billsURL = location.protocol + '//' + location.host + '/' + 'api/' + 'Bills/';
app.config.globalProperties.Timezone = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2] || ''; 
app.config.globalProperties.Year = 2023;
app.config.globalProperties.GlobalLoading = false;


var committeeShortCodes = null;

//Vue.use(vuetify);


window.myfetch = function (resource, init) {
    init = init || {};
    init.credentials = 'same-origin';

    return fetch(resource, init).then(function (response) {
        if (response.status == 405)
            document.location.assign(location.protocol + '//' + location.host + '?ReturnUrl=' + encodeURIComponent(document.location));
        else
            return response;
    });
};
window.debounce = function (fn, delay) {
    var timeoutID = null;
    return function () {
        clearTimeout(timeoutID)
        var args = arguments
        var that = this
        timeoutID = setTimeout(function () {
            fn.apply(that, args)
        }, delay)
    }
};
//https://github.com/vuejs-tips/tiny-debounce
/* eslint-disable no-new */
//const app = new Vue({
//    el: '#app',
//    vuetify,
//    router: router,
//    store: store,
//    components: { App },
//    template: '<App/>',
//    render: h => h(App)

//});

gtag('event', 'page_view', { 'page_location': window.location.href.replace('#', '') });

router.afterEach(function (to, from) {
    gtag('event', 'page_view', { 'page_location': window.location.href.replace('#', '') });
});

router.beforeEach((to, from, next) => 
{
    if (to.fullPath.substr(0, 6).toLowerCase() === "/main#")
    {
        const path = "/Main" + to.fullPath.substr(6);
        next( { path: path, query: to.query });
    }

    next();
});

app.directive('sortable', {
    inserted: function (el, binding) {
        new Sortable(el, binding.value || {})
    }
});


window.FormatMonths = function (value) {
    return moment(value, "YYYY-MM-DD").format("MMM");
}
//Vue.filter('FormatMonths', FormatMonths);
window.FormatTimes = function (value) {
    if (!isValidDate(value))
        return value;
    let TimeZone = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2] || '';
    if (value.getHours() == 0)
        return moment(value).format('MM-DD-YYYY');
    return moment(value).format('h A') + ' ' + TimeZone;
    //return value.toLocaleDateString();
    //return value.toLocaleString();
}
//Vue.filter('FormatTimes', FormatTimes);
window.FormatDates = function (value) {
    if (!isValidDate(value))
        return value;
    let TimeZone = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2] || '';
    if (value.getHours() == 0)
        return moment(value).format('MM-DD-YYYY');
    return moment(value).format('MM-DD-YYYY, h:mm A') + ' ' + TimeZone;
    //return value.toLocaleDateString();
    //return value.toLocaleString();
}

//Vue.filter('FormatDates', FormatDates);
window.FormatDatesLongDay = function (value) {
    if (!isValidDate(value))
        return value;
    if (value.getHours() == 0)
        return moment(value).format('dddd, MMMM DD, YYYY');
    return moment(value).format('dddd, MMMM DD, YYYY h:mm A');
}
//Vue.filter('FormatDatesLongDay', FormatDatesLongDay);


window.FormatDateTimes = function (value) {
    if (!isValidDate(value))
        return value;

    let TimeZone = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2] || '';

    return moment(value).format('MM/DD/YYYY h:mm A') + ' ' + TimeZone;
}
//Vue.filter('FormatDateTimes', FormatDateTimes);

window.BillRating = function (value) {
    if (value == 1)
        return 'For Bill';
    else if (value == 2)
        return 'Against Bill';
    else
        return 'Watching Bill';
}
//Vue.filter('BillRating', BillRating);

window.MemberTypeFilter = function (value) {
    return MemberType(value);
}
function MemberType(value) {
    if (value == 'S') {
        return 'Senator'
    }
    else {
        return 'Representative'
    }
}
//Vue.filter('MemberTypeFilter', MemberTypeFilter);

window.MemberTypeAbbreviationFilter = function (value) {
    return MemberTypeAbbreviation(value);
}
function MemberTypeAbbreviation(value) {
    if (value == 'S') {
        return 'Sen.'
    }
    else {
        return 'Rep.'
    }
}
window.MemberTypeAbbreviation = MemberTypeAbbreviation
//Vue.filter('MemberTypeAbbreviationFilter', MemberTypeAbbreviationFilter);

//Vue.filter('ShowTime', function (value) {
//    if (!isValidDate(value))
//        return value;
//    return value.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
//});
function CommitteeBody(value) {
    if (value == 'J') {
        return 'Joint';
    }
    else if (value == 'S') {
        return 'Senate';
    }
    else {
        return 'House';
    }
}
window.CommitteeBodyFilter = function (value) {
    return CommitteeBody(value);
}
//Vue.filter('CommitteeBodyFilter', CommitteeBodyFilter);
function ellipsis(d, cutoff, wordbreak, escapeHtml) {
    var esc = function (t) {
        return t
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;');
    };
    if (typeof d !== 'number' && typeof d !== 'string') {
        return d;
    }
    d = d.toString(); // cast numbers
    if (d.length <= cutoff) {
        return d;
    }
    var shortened = d.substr(0, cutoff - 1);
    // Find the last white space character in the string
    if (wordbreak) {
        shortened = shortened.replace(/\s([^\s]*)$/, '');
    }
    // Protect against uncontrolled HTML input
    if (escapeHtml) {
        shortened = esc(shortened);
    }
    return '<span class="ellipsis" title="' + esc(d) + '">' + shortened + '&#8230;</span>';
};

function isValidDate(possibledate) {
    let date = EncodeDatetimes(possibledate);
    return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
}
// First load the UMD module dependency and attach to global scope again

//window.VueTour = Vue3Tour; // re-attach to global scope


//require(['vue-cal'], function (vuecal) {
//    window.vuecal = vuecal;
//    Vue.use(vuecal);
//});
var s = window.location.pathname.split('/')[1];
if (s.length !== 0) {
    s = s + '/';
}



var mainjsError = null;
var mainjsErrorSource = null;

app.config.errorHandler = function (err, vm, info) {
    if (err) {
        
        mainjsError = err;
        mainjsErrorSource = null;
        StackTrace.fromError(err).then(callback).catch(errback);
    }
    try {
        let url = null;
        if (window && window.location) {
            url = window.location.href;
        }
        // `info` is a Vue-specific error info, e.g. which lifecycle hook // the error was found in. Only available in 2.2.0+
        fetch("/api/Errors", {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                Message: err.message || "",
                Source: "", //JSON.stringify({ vm }, getCircularReplacer()),
                StackTrace: err.stack,
                Path: url,
                DateOccured: new Date()
            }),
        });
        console.error(err, vm, info);
    }
    catch (ex) {
        console.error(ex);
    }
}
window.onerror = function (msg, src, linenum, colnum, error) {
    
    if (error) {
        mainjsError = error;
        mainjsErrorSource = src;
        StackTrace.fromError(error).then(callback).catch(errback);
        try {
            var eerror = JSON.stringify({
                Message: msg || "",
                Source: src || "",
                StackTrace: (error || "") + ("\n\rLine Number" + linenum || "") + ("\n\rCol Num" + colnum || ""),
                DateOccured: new Date()
            }, getCircularReplacer());
            myfetch(errorURL, {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: eerror,
            }).then(res => { res.json() })
                .then(response => console.error(res))
                .catch(error => console.error(error));
        }

        catch (error) {
            console.error(error);
        }
    }
};
var errback = function (err) {
    console.error(err);
};
var callback = function (stackframes) {
    var stringifiedStack = stackframes.map(function (sf) {
        return sf.toString();
    }).join('\n');
    try {
        let url = null;
        if (window && window.location) {
            url = window.location.href;
        }
        //todo uncomment
        fetch("/api/Errors", {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                Message: mainjsError.message || "",
                Source: mainjsErrorSource, //JSON.stringify({ vm }, getCircularReplacer()),
                StackTrace: stringifiedStack,
                Path: url,
                DateOccured: new Date()
            }),
        });
        console.error(mainjsError);
    }
    catch (ex) {
        console.error(ex);
    }
};

const getCircularReplacer = function () {
    const seen = new WeakSet();
    return function (key, value) {
        if (typeof value === "object" && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};
function toLetters(num) {
    "use strict";
    var mod = num % 26,
        pow = num / 26 | 0,
        out = mod ? String.fromCharCode(64 + mod) : (--pow, 'Z');
    return pow ? toLetters(pow) + out : out;
}
app.config.globalProperties.$filters = {
    FormatDates,
    FormatDatesLongDay,
    MemberTypeFilter,
    CommitteeBodyFilter,
    MemberTypeAbbreviationFilter,
    BillRating
}
app.use(VueCookies);
app.use(vuetify);
app.use(Vue3Tour)
app.use(router);
app.use(pinia);
router.isReady().then(() => {
    app.mount('#app');
});